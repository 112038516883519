// extracted by mini-css-extract-plugin
export var container = "your-spaceflight-section-module--container--7KWZW";
export var experienceParagraph = "your-spaceflight-section-module--experience-paragraph--qf7kH";
export var experienceImageContainer = "your-spaceflight-section-module--experience-image-container--yHu5r";
export var imageWrapper = "your-spaceflight-section-module--image-wrapper--2R3ai";
export var image = "your-spaceflight-section-module--image--v2BGg";
export var titleWrapper = "your-spaceflight-section-module--title-wrapper--Zs6qp";
export var uniteParagraph = "your-spaceflight-section-module--unite-paragraph--NO7y6";
export var uniteImageContainer = "your-spaceflight-section-module--unite-image-container--dGwUu";
export var subtitle = "your-spaceflight-section-module--subtitle--JVnMS";
export var list = "your-spaceflight-section-module--list--gbvZ9";
export var inner = "your-spaceflight-section-module--inner--oVTpk";