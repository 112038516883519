import React from 'react';
import * as styles from './your-spaceflight-section.module.css';
import Container from '../Container';
import TypographyBold from '../TypographyBold';
import SectionTitle from '../SectionTitle';
import spaceImage from './images/space.jpg';
import spaceMobImage from './images/space-mob.jpg';
import changeTheWorldImage from './images/blast-team.png';


const YourSpaceflightSection = () => {
  return (
    <section className={styles.container}>
      <Container size='sm'>
        <div className={styles.inner}>
          <p className={styles.experienceParagraph}>
            Having experienced the life-changing Overview Effect firsthand, our hope is that you return from space motivated to deepen your connection to Earth and to humanity. As an astronaut and member of our community, we’re thrilled to democratize space together, for the good of humankind.
          </p>
          <div className={styles.experienceImageContainer}>
            <picture className={styles.imageWrapper}>
              <source srcSet={spaceMobImage} media='(max-width: 768px)'/>
              <img src={spaceImage} className={styles.image} alt='Space' />
            </picture>
          </div>
          <div className={styles.titleWrapper}>
            <SectionTitle>
              Your spaceflight can
              <TypographyBold> change the world.</TypographyBold>
            </SectionTitle>
          </div>
          <p className={styles.uniteParagraph}>
            From the moment you join us, your space experience can inspire positive change through your participation with Galactic Unite, Virgin Galactic’s outreach initiative. Born out of a collaboration with our very own Future Astronauts, Galactic Unite has invested more than $2 million USD through scholarship, fellowship and grant programs, to support and inspire young people to pursue an education in STEM.
          </p>
          <div className={styles.uniteImageContainer}>
            <picture className={styles.imageWrapper}>
              <img src={changeTheWorldImage} className={styles.image} alt='Unite' />
            </picture>
          </div>
          <h3 className={styles.subtitle}>
            How You Can Support
            <TypographyBold variant='mobile'> Galactic Unite</TypographyBold>
          </h3>
          <ul className={styles.list}>
            <li>
              • Fund a scholarship or grant
            </li>
            <li>
              • Support existing programs like BLAST
              <br/>
              (Black Leaders in Aerospace Scholarship and Training)
            </li>
            <li>
              • Participate in a virtual online ‘Spacechat’
            </li>
            <li>
              • Volunteer time as a mentor
            </li>
            <li>
              • Run Spacechats with local schools
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
};

export default YourSpaceflightSection;
