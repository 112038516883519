import React from 'react';
import Layout from '../components/Layout';
import * as styles from './new-perspective.module.css';
import PageTitle from '../components/PageTitle';
import ReadyToBecomeAstronautSection from '../components/ReadyToBecomeAstronautSection';
import Container from '../components/Container';
import previewImg from './images/new-perspective-page-preview-section-bg.jpg';
import previewMobImg from './images/new-perspective-page-preview-section-bg-mob.jpg';
import PreviewSection from '../components/PreviewSection';
import TypographyBold from '../components/TypographyBold';
import YourSpaceflightSection from '../components/YourSpaceflightSection';
import PreviewSectionInner from '../components/PreviewSectionInner';

const NewPerspectivePage = () => {
  return (
    <Layout pageTitle='Virgin Galactic | New Perspective' description='Spaceflight is a transformative life experience that will shift your perspective. '>
      <PreviewSection className={styles.previewSection} image={previewImg} mobImage={previewMobImg} alt='Astronaut'>
        <Container size='sm'>
          <PreviewSectionInner>
            <div className={styles.pageTitleWrapper}>
              <PageTitle>
                Become an astronaut,
                <br/>
                <TypographyBold> become more human.</TypographyBold>
              </PageTitle>
            </div>
          </PreviewSectionInner>
        </Container>
      </PreviewSection>
      <YourSpaceflightSection />
      <ReadyToBecomeAstronautSection />
    </Layout>
  );
};

export default NewPerspectivePage;
